import React from 'react';
import FeedStatic from '../../components/FeedStatic';
import ListCardsText from '../../components/ListCardsText';

const FeedWithListCardsText = ({
  title,
  labelLink,
  slugArchive,
  relatedNews
}) => {
  return (
    <FeedStatic
      marginTop="zero"
      marginBottom="zero"
      title={title}
      labelLink={labelLink}
      url={slugArchive}
      hasBackground
      hasPadding
    >
      <ListCardsText
        slugArchive={slugArchive}
        cards={relatedNews}
      />
    </FeedStatic>
  );
};

export default FeedWithListCardsText;
