import React from 'react';
import { InternalLink } from '../Link';
import Container from '../Container';

const FeedStatic = ({
  marginTop,
  marginBottom,
  title,
  labelLink,
  url,
  hasBackground,
  hasPadding,
  children,
  handleArchiveClick,
}) => {
  const dictionary = {
    home: 'Notizie e Aggiornamenti',
    news: 'News ed Aggiornamenti',
    eventi: 'Eventi',
    pubblicazioni: 'Pubblicazioni Correlate',
    salaStampa: 'Comunicati stampa',
    storie: 'Ultime storie',
    localNews: 'News locali correlate'
  };

  const labelTitle = dictionary[title];

  return (
    <Container
      marginTop={marginTop}
      marginBottom={marginBottom}
      className={`feed ${hasBackground ? 'feed--background' : ''} ${hasPadding ? 'feed--with-padding' : ''}`}
    >
      <header className="feed__title-container">
        {title && <h2 className="feed__title heading-md">{labelTitle}</h2>}
        {
          url && (
            <div className="feed__top-link">
              <InternalLink
                kind="secondary"
                label={labelLink}
                color="brand"
                url={`/${url}`}
                onClick={handleArchiveClick}
              />
            </div>
          )
        }
      </header>
      {
        children && (
          <div className="feed__cards-grid">
            {children}
          </div>
        )
      }
      {
        url && (
          <footer className="feed__bottom-link">
            <InternalLink
              kind="secondary"
              label={labelLink}
              color="brand"
              url={`/${url}`}
              onClick={handleArchiveClick}
            />
          </footer>
        )
      }
    </Container>
  );
};

FeedStatic.defaultProps = {
  labelLink: 'Vedi tutte'
};

export default FeedStatic;
